<template>
  <body>
    <div id="containerMesh" />
    <div id="loading-screen">

      <div id="loader" />

    </div>
  </body>
</template>

<style>
#loading-screen {
position: absolute;
z-index: 2;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #000000;
opacity: 1;
transition: 1s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>

<script>
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

require('three/examples/js/loaders/OBJLoader')
require('three/examples/js/controls/OrbitControls')

let scene
let camera
let renderer
let mesh

window.onresize = function () {
  camera.aspect = window.innerWidth / window.innerHeight
  camera.updateProjectionMatrix()

  renderer.setSize(window.innerWidth, window.innerHeight)
}
export default {
  name: 'ThreeTest',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
    }
  },
  mounted() {
    // window.addEventListener('load', () => {
    this.init()
    // })
    // this.animate()
  },
  methods: {
    insertAfter(referenceNode, newNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
    },
    onTransitionEnd(event) {
      const element = event.target
      element.remove()
    },
    init() {
      const container = document.getElementById('containerMesh')

      scene = new THREE.Scene()

      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
      camera.position.z = 160

      renderer = new THREE.WebGLRenderer()
      renderer.setSize(window.innerWidth, window.innerHeight)

      const controls = new OrbitControls(camera, renderer.domElement)
      controls.enablePan = true
      controls.enableRotate = true

      scene.add(new THREE.AmbientLight(0x404040))

      const loadingManager = new THREE.LoadingManager(() => {
        const loadingScreen = document.getElementById('loading-screen')
        loadingScreen.classList.add('fade-out')

        // optional: remove loader from DOM via event listener
        loadingScreen.addEventListener('transitionend', this.onTransitionEnd)
      })

      // loader.load('https://aerodyne-mco-data.s3.ap-southeast-1.amazonaws.com/VOPS/TEST-MESH/CRANE-P4D/6169d501b268ba221c54f95a_simplified_3d_mesh.obj',
      // loader.load('https://cdn.glitch.com/fcf3c007-b4eb-4250-ba6b-653fdab94ce3%2Fjapanese_temple.obj?1558792651869',

      // loader.loadMtl(`${this.publicPath}files/object/odm_textured_model_geo.mtl`, null, materials => {
      //   loader.setMaterials(materials)
      //   loader.load(`${this.publicPath}files/object/odm_textured_model_geo.obj`,
      //     obj => {
      //       const material = new THREE.PointsMaterial({ color: 0xFFFFFF, size: 0.25 })
      //       mesh = new THREE.Points(obj.children[0].geometry, material)
      //       mesh.position.y = -15
      //       // mesh.position.x = -15
      //       scene.add(mesh)
      //     },
      //     xhr => {
      //       console.log(xhr)
      //     },
      //     err => {
      //       console.error("loading .obj went wrong, ", err)
      //     })
      // })
      const textureLoader = new THREE.TextureLoader(loadingManager)
      // const texture = textureLoader.load(`${this.publicPath}files/object/odm_textured_model_geo_material0000_map_Kd.png`)
      const textureArray = []
      for (let i = 0; i < 91; i += 1) {
        if (i < 10) {
          textureArray.push(new THREE.MeshBasicMaterial({ map: textureLoader.load(`${this.publicPath}files/object/odm_textured_model_geo_material000${i}_map_Kd.png`) }))
        } else {
          textureArray.push(new THREE.MeshBasicMaterial({ map: textureLoader.load(`${this.publicPath}files/object/odm_textured_model_geo_material00${i}_map_Kd.png`) }))
        }
      }

      const mtlLoader = new MTLLoader(loadingManager)
      mtlLoader.setPath(`${this.publicPath}files/object/`)
      mtlLoader.load(
        'odm_textured_model_geo.mtl',
        materials => {
          materials.preload()
          const objLoader = new OBJLoader(loadingManager)
          objLoader.setMaterials(materials)
          objLoader.setPath(`${this.publicPath}files/object/`)
          objLoader.load(`odm_textured_model_geo.obj`,
            obj => {
              // const material = new THREE.PointsMaterial({ color: 0xFFFFFF, size: 0.25 })
              // mesh = new THREE.Points(obj.children[0].geometry, material)
              const material = textureArray
              mesh = new THREE.Mesh(obj.children[0].geometry, material)
              mesh.position.y = -15
              // mesh.position.x = -15
              scene.add(mesh)
            },
            xhr => {
              console.log(xhr)
            },
            err => {
              console.error("loading .obj went wrong, ", err)
            })
        },
      )

      // loader.load(`${this.publicPath}files/object/odm_textured_model_geo.obj`,
      //   obj => {
      //     const material = new THREE.PointsMaterial({ color: 0xFFFFFF, size: 0.25 })
      //     mesh = new THREE.Points(obj.children[0].geometry, material)
      //     mesh.position.y = -15
      //     // mesh.position.x = -15
      //     scene.add(mesh)
      //   },
      //   xhr => {
      //     console.log(xhr)
      //   },
      //   err => {
      //     console.error("loading .obj went wrong, ", err)
      //   })

      // document.body.appendChild(renderer.domElement)
      // const div = document.getElementById("info")
      // this.insertAfter(div, renderer.domElement)
      container.appendChild(renderer.domElement)
      this.animationLoop()
    },
    animationLoop() {
      renderer.render(scene, camera)
      // if (mesh) {
      //   mesh.rotation.y += 0.005
      // }
      requestAnimationFrame(this.animationLoop)
    },
  },
}
</script>
